import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { getEllipsisTxt } from './helpers/formatters'
import APIService from "./service/APIService"
import CONSTANT from './Constant'
import { useMoralis } from 'react-moralis'

async function getHeroInfo(id) {
  try {
    const response = await APIService.getDetailHero(id)
    return response
  } catch (error) {
    console.error(error)
  }
}

const HeroesDetail = () => {
  let { id, type } = useParams()
  const { Moralis } = useMoralis()
  const [transaction, setTransaction] = useState([])

  useEffect(() => {
    async function getHeroTrans() {
      let info = await getHeroInfo(id)

      const options = {
        address: CONSTANT.NFTAddress,
        token_id: info.tokenId,
        chain: '0x61',
      }
      const trans = await Moralis.Web3API.token.getWalletTokenIdTransfers(
        options
      )
      setTransaction(trans.result)
    }

    getHeroTrans()
  }, [])

  return (
    <div className="w-full p-4 lg:p-0 bg-contain bg-center bg-no-repeat bg-marketplace mt-20">
      <div className="lg:w-[1140px] lg:m-auto flex flex-col items-center">
        <div className="font-title text-4xl text-white self-start">
          PLAYER INFO
        </div>
        <div className="mt-6 flex flex-row flex-wrap w-full h-[67px] justify-around items-center lg:bg-main-black rounded-md">
          <div className="flex flex-col items-center justify-center">
            <p className="text-base">
              <img className="inline" alt="" src="/images/ico1.png" /> Win
            </p>
            <div className="flex flex-row items-center gap-2 text-base">
              <span>0</span>
            </div>
          </div>
          <div className="w-[1px] h-[34px] bg-gray-400"></div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-base">
              <img className="inline" alt="" src="/images/ico1.png" /> Lose
            </p>
            <div className="flex flex-row items-center gap-2 text-base">
              <span>0</span>
            </div>
          </div>
          <div className="w-[1px] h-[34px] bg-gray-400"></div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-base">
              <img className="inline" alt="" src="/images/ico1.png" /> Luck
            </p>
            <div className="flex flex-row items-center gap-2 text-base">
              <span>0</span>
            </div>
          </div>
        </div>
        <div className="font-title text-4xl text-white self-start mt-6">
          PLAYING
        </div>
        <div className="mt-6 flex flex-row w-full h-[67px] justify-around items-center lg:bg-main-black rounded-md">
          <div className="flex flex-col items-center justify-center">
            <p className="text-base">
              <img className="inline" alt="" src="/images/ico3.png" /> Champion
            </p>
            <div className="flex flex-row items-center gap-2 text-base">
              <span className="text-main-green-light">Rare</span>
            </div>
          </div>
          <div className="w-[1px] h-[34px] bg-gray-400"></div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-base">
              <img className="inline" alt="" src="/images/ico2.png" /> Status
            </p>
            <div className="flex flex-row items-center gap-2 text-base">
              <span className="text-main-green-light">Rare</span>
            </div>
          </div>
          <div className="w-[1px] h-[34px] bg-gray-400"></div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-base">
              <img className="inline" alt="" src="/images/ico3.png" /> Active
              Time
            </p>
            <div className="flex flex-row items-center gap-2 text-base">
              <span className="text-main-green-light">Rare</span>
            </div>
          </div>
        </div>
        {transaction.length > 0 && (
          <>
            <div className="font-title text-4xl text-white self-start mt-6">
              HISTORY
            </div>
            <div className="h-[53px] w-full rounded-t-lg bg-[#252934] flex flex-row items-center mt-8 font-bold text-center">
              <span className="w-20 lg:w-80">FROM</span>
              <span className="w-20 lg:w-80">TO</span>
              <span className="w-20">PRICE</span>
              <span className="flex-grow">TIME</span>
            </div>
            <div className="w-full overflow-auto rounded-b-lg bg-black bg-opacity-60 flex flex-col items-center justify-center mb-8 gap-5 pt-4 pb-4">
              {transaction.map((e) => {
                return (
                  <div
                    key={e.block_hash}
                    className="w-full  flex flex-row items-center justify-around text-center"
                  >
                    <span className="w-20 lg:w-80">
                      {getEllipsisTxt(e.from_address, 4)}
                    </span>
                    <span className="w-20 lg:w-80">
                      {getEllipsisTxt(e.to_address, 4)}
                    </span>
                    <span className="w-20">
                      {Moralis?.Units?.FromWei(e.value, 18)} BNB
                    </span>
                    <span className="flex-grow">
                      {new Date(e.block_timestamp).toDateString()}
                    </span>
                  </div>
                )
              })}
            </div>
            {/* <div className="p-2 px-4 rounded-2xl bg-main-orange text-black text-sm font-bold">
              MAKE OFFER
            </div> */}
          </>
        )}
      </div>
    </div>
  )
}

export default HeroesDetail
