/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay } from 'swiper'
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom'
import { ListMember, ListLeader } from "../src/store/DataTeam"
import { Modal } from "antd"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/autoplay'
import Popup from './components/Popup'
const App = () => {
  const [firstSwiper, setFirstSwiper] = useState(null)
  const [secondSwiper, setSecondSwiper] = useState(null)
  const [modalBanner, setModalBanner] = useState(true)
  const navigate = useNavigate()

  // useEffect(() => {
  //   let visited = localStorage["alreadyVisited"];
  //   if(!visited) {
  //     localStorage["alreadyVisited"] = true;
  //     setModalBanner(true)
  //   } 
  // }, [])

  return (
    <div className="w-full lg:w-[1140px] lg:m-auto p-4 lg:p-0 flex flex-col items-center">
      <div className="w-full flex flex-col lg:m-auto items-center bg-marketplace bg-contain bg-bottom bg-no-repeat">
        <img className="my-6" src="./images/line-new.png" />
        <img src="./images/title.png" />
        <div>
          <p>
            GoBall is a unique football game on <a className='font-bold text-[#e9ba41]' href='https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain' target="_blank">Binance Smart Chain (BSC)</a>. We
            are desire to create a platform not just for NFT collection, but
            also a platform in which users can equip their NFT players with
            beautiful hand-crafted items, and experience the thrill of
            fast-paced multiplayer soccer matches!
          </p>
          <p className="mt-4 text-main-green-light">Game highlights:</p>
          <p>
            - GoBall is developed on the basis of SKILL TO WIN & FARM TO EARN
            <br />
            - Fight against real opponents in real time for 60 seconds
            <br />
            - Each match has 3 rounds. Whoever scores earlier is selected to win
            if there is a tie
            <br />
            - Competition Mode: players search for skill items as well as
            special mysterious NFT characters
            <br />
            - Arena Mode: collect extremely attractive Champion tickets
            <br />- Global Champion Mode: with extremely attractive gifts for
            winners and crown effects that everyone must aspire to have.
          </p>
        </div>
        {/* <div className="mt-5">
          <iframe
            className="w-full lg:w-[640px]"
            height="360"
            src="https://www.youtube.com/embed/WgzxAwasxW8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div> */}

        <img className="my-6" src="./images/line-new.png" />
        <p className="font-bold text-4xl">CHARACTERS</p>
        <div className="w-full lg:w-1140 mt-3 flex flex-row justify-between items-center">
          <img
            onClick={() => {
              firstSwiper.slidePrev()
            }}
            className="h-6"
            src="./images/prev_button.png"
          />
          <Swiper
            className="ml-2 lg:ml-16"
            loop={true}
            modules={[Autoplay]}
            slidesPerView={5}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            onSwiper={setFirstSwiper}
          >
            <SwiperSlide>
              <div className="h-40 grid justify-items-center items-center">
                <img
                  className="w-[51px] lg:w-[99px] hover:scale-125"
                  src="./images/char1.png"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-40 grid justify-items-center items-center">
                <img
                  className="w-[51px] lg:w-[99px] hover:scale-125"
                  src="./images/char2.png"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-40 grid justify-items-center items-center">
                <img
                  className="w-[51px] lg:w-[99px] hover:scale-125"
                  src="./images/char3.png"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="h-40 grid justify-items-center items-center">
                <img
                  className="w-[51px] lg:w-[99px] hover:scale-125"
                  src="./images/char4.png"
                />
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="h-40 grid justify-items-center items-center">
                <img
                  className="w-[51px] lg:w-[99px] hover:scale-125"
                  src="./images/char6.png"
                />
              </div>
            </SwiperSlide>
          </Swiper>
          <img
            onClick={() => {
              firstSwiper.slideNext()
            }}
            className="h-6"
            src="./images/next_button.png"
          />
        </div>

        <img className="my-6" src="./images/line-new.png" />
      </div>
      <div className="flex flex-col lg:flex-row items-center">
        <img
          className="w-[248px] lg:w-[524px]"
          src="./images/char-detail.png"
        />
        <div>
          <p className="font-title text-main-orange text-7xl flex justify-center lg:justify-end items-center lg:items-end">
            <span className="text-white text-4xl font-bold mr-2">
              CHARACTERS
            </span>
          </p>
          <p className=" ">
            Any sport enthusiast cannot ignore <a className='font-bold text-[#fada5d]' href='/instruction' target="_blank">Goballio</a> - a solo football game
            with unique gameplay that is extremely interesting online.
            <br />
            <br />
            GoBall has exquisitely digital collectibles created on Binance Smart
            Chain. Each collectible is unique and varies in rarity. The basic
            attributes of a player include: Speed, Jump, Shoot, Size. To ensure
            fairness among all players, the attributes stats are the same at the
            beggining. Players can develop character's skills through
            tournaments as well as through in-game items.
            <br />
            <br />
            Own your favorite character and compete in thrilling matches in
            GoBall's worldwide championship
          </p>
          <div className="flex flex-row flex-wrap gap-3 justify-center">
            <div className="relative mt-6 ml-6">
              <img
                className="absolute -left-6 -top-1 w-[68px] h-[68px]"
                src="/images/skill4.png"
              />
              <div className="float-left border-2 border-main-green rounded-xl h-14 w-164 pt-1.5 pl-12 bg-main-blue-dark text-sm">
                <span className="text-main-orange font-bold text-sm">
                  EVOLVE <br />
                  POTION
                </span>
              </div>
            </div>
            <div className="relative mt-6 ml-6">
              <img
                className="absolute -left-6 -top-1 w-[68px] h-[68px]"
                src="/images/skill5.png"
              />
              <div className="float-left border-2 border-main-green rounded-xl h-14 w-164 pt-1.5 pl-12  bg-main-blue-dark text-sm">
                <span className="text-main-orange font-bold">
                  NITRO
                  <br />
                  SPRAY
                </span>
              </div>
            </div>
            <div className="relative mt-6 ml-6">
              <img
                className="absolute -left-6 -top-1 w-[68px] h-[68px]"
                src="/images/skill6.png"
              />
              <div className="float-left border-2 border-main-green rounded-xl h-14 w-164 pt-1.5 pl-12  bg-main-blue-dark text-sm">
                <span className="text-main-orange font-bold">
                  SUPER
                  <br />
                  SPRING
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <img className="my-6" src="./images/line-new.png" />
      <p className="font-title text-main-orange text-7xl flex flex-row justify-center lg:justify-start w-full mb-6">
        <img src="./images/item-title.png" alt="hello" />
      </p>
      <div className="flex flex-col lg:flex-row justify-between w-full">
        <div className="lg:w-[500px]">
          <p className=" ">
          Items in <a className='font-bold text-[#fada5d]' href='/instruction' target="_blank">Goball.io</a> are important, choosing the right special item to use makes your opponent unable to
            react and skillfully uses a combo to score goals in your creative
            way. <br />
            <br />
            Players can collect items through tournaments or buy from other
            players.
          </p>
        </div>
        <div className="flex  mt-4 lg:mt-0 flex-row flex-wrap justify-around items-center lg:gap-10">
          <img
            className="w-[93px] h-[112px]"
            src="./images/item1.png"
            alt="item"
          />
          <img
            className="w-[93px] h-[112px]"
            src="./images/item2.png"
            alt="item"
          />
          <img
            className="w-[93px] h-[112px]"
            src="./images/item3.png"
            alt="item"
          />
          <img
            className="w-[93px] h-[112px]"
            src="./images/item4.png"
            alt="item"
          />
        </div>
      </div>

      <img className="mt-12 mb-12" src="./images/line-new.png" />
      <div className="flex-row lg:w-1140 justify-around items-center hidden lg:flex">
        {/* <img src="./images/roadmap-l.png" />
        <img className="w-344 h-279" src="./images/roadmap-r.png" /> */}
        <img src="./images/roadmap-full.png" />
      </div>

      <div className="flex flex-row lg:w-1140 justify-around items-center lg:hidden">
        <img src="./images/roadmap-mobile.png" />
      </div>

      <img
        className="mt-12 mb-12 hidden lg:block"
        src="./images/line-new.png"
      />
      <div className="font-title text-4xl text-white self-center mt-12 lg:mt-0">
        <img src="./images/txtOurTeam.png" alt="hello" />
      </div>


      <div className="mt-10 justify-center flex flex-row flex-wrap gap-8 lg:w-[930px]">
        {ListLeader.map((x) => (
          <div className="flex items-center flex-col relative w-[128px]" key={x.id}>
            <img
              src={'./images/bg_member.png'}
            />
            <img className='absolute top-[-15px]'
              src={'./images/team/' + x.id + '.png'}
            />
            <p className='mt-4 font-bold'>
              {x.name}
            </p>
            <p className='min-h-[21px]'>
              {x.name2}
            </p>
            <p className='font-bold'>
              {x.name3}
            </p>
            <p className='text-center mt-2 text-[#F4C711]' >
              {x.office}
            </p>
          </div>
        ))}
      </div>

      <div className="mt-10 justify-center lg:justify-start flex flex-row flex-wrap gap-8 lg:w-[930px]">
        {ListMember.map((x) => (
          <div className="flex items-center flex-col relative w-[128px] " key={x.id}>
            <img
              src={'./images/bg_member.png'}
            />
            <img className='absolute top-[-15px]'
              src={'./images/team/' + x.id + '.png'}
            />
            <p className='mt-4 font-bold'>
              {x.name}
            </p>
            <p className='min-h-[21px]'>
              {x.name2}
            </p>
            <p className='font-bold'>
              {x.name3}
            </p>
            <p className='text-center mt-2 text-[#F4C711]' >
              {x.office}
            </p>
          </div>
        ))}
      </div>
      <Modal
        className='custom-modal'
        visible={modalBanner}
        centered
        onCancel={() => setModalBanner(false)}
        closable={false}
        footer={null}
        width="800px"
      >
        <div className='flex z-40'>
          <div className='bg-[#252934] m-auto rounded-lg border-[#7bb208] overflow-hidden	 border-[1px] relative'>
            <button className='absolute top-0 right-0' onClick={() => { setModalBanner(false) }} >
              <img src="/images/ic_closepopup.png" alt="" />
            </button>
            <Link to="ref/0">
              <img
                className="h-[220px] sm:h-[420px] lg:h-[420px] w-[800px]"
                src="/images/popup_home.png"
              />
            </Link>
          </div>

        </div>

      </Modal>
      {/* <div className="font-title text-4xl text-white self-start mt-12 lg:mt-0">
        BACKED BY
      </div>

      <div className="mt-6 flex flex-row flex-wrap justify-center gap-5 lg:w-864">
        {[...Array(29)].map((x, i) => (
          <div className="rounded-2xl bg-main-grey px-2 py-1 h-14 flex items-center">
            <img
              className={i == 23 ? 'h-6 w-32' : 'h-11 w-32'}
              src={'./images/backer/' + (i + 1) + '.png'}
            />
          </div>
        ))}
      </div> */}
    </div >
  )
}

export default App
